<template>
  <div>
    <v-row>
      <v-col>
        <v-text-field
          :value="data.reportedWeight"
          @input="emitUpdate('reportedWeight', Number($event))"
          :rules="[rules.required]"
          label="Masa odpadów"
          type="number"
          placeholder="Masa odpadów w tonach"
          outlined
          dense
          @wheel="$event.target.blur()"
        />
      </v-col>
      <v-col>
        <v-text-field
          :value="data.vehicleRegNumber"
          @input="emitUpdate('vehicleRegNumber', $event)"
          label="Numer rejestracyjny pojazdu"
          placeholder="Numer rejestracyjny pojazdu"
          outlined
          dense
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-autocomplete
          :value="data.receiverBusinessPlaceId"
          @input="emitUpdate('receiverBusinessPlaceId', $event)"
          :rules="[rules.required]"
          item-text="displayName"
          item-value="id"
          :items="receiverBusinessPlaces"
          label="MPD przejmującego"
          placeholder="MPD przejmującego"
          outlined
          dense
        />
      </v-col>
      <v-col>
        <v-autocomplete
          :value="data.carrierBusinessPlaceId"
          @input="emitUpdate('carrierBusinessPlaceId', $event)"
          :items="carrierBusinessPlaces"
          item-text="displayName"
          item-value="id"
          :rules="[rules.required]"
          label="MPD transportującego"
          placeholder="MPD transportującego"
          outlined
          dense
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-autocomplete
          :value="data.senderCity"
          @input="emitUpdate('senderCity', $event)"
          item-text="value"
          item-value="key"
          :items="senderCities"
          :rules="[rules.required]"
          return-object
          label="MPD przekazującego"
          placeholder="MPD przekazującego"
          outlined
          dense
        />
      </v-col>
      <v-col>
        <v-autocomplete
          :value="data.debrisTypeId"
          @input="emitUpdate('debrisTypeId', $event)"
          :items="bdoCardTemplateDebrisTypes"
          :rules="[rules.required]"
          item-text="name"
          item-value="id"
          label="Typ odpadu"
          placeholder="Typ odpadu"
          outlined
          dense
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-text-field
          :value="data.wasteCodeExtendedDescription"
          @input="emitUpdate('wasteCodeExtendedDescription', $event)"
          label="Ex info"
          placeholder="Ex info"
          outlined
          dense
        />
      </v-col>
      <v-col>
        <div class="d-flex align-center">
          <span class="v-label mr-4">Kod ex</span>
          <v-switch
            :value="data.wasteCodeExtended"
            :true-value="true"
            :false-value="false"
            hide-details
            @change="emitUpdate('wasteCodeExtended', $event)"
          />
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import rules from '../../../utils/validators'

export default {
  data() {
    return {
      rules,
    }
  },
  props: {
    data: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    ...mapState({
      senderCities: state => state.core.filters.senderCities,
      bdoCardTemplateDebrisTypes: state => state.core.filters.bdoCardTemplateDebrisTypes,
      receiverBusinessPlaces: state => state.core.filters.receiverBusinessPlaces,
      carrierBusinessPlaces: state => state.core.filters.carrierBusinessPlaces
    })
  },
  async mounted() {
    await this.setBdoCardTemplatesOptions()
  },
  methods: {
    ...mapActions({
      setBdoCardTemplatesOptions: 'core/setBdoCardTemplatesOptions'
    }),
    emitUpdate(key, value) {
      this.$emit('update', { key, value })
    }
  }
}
</script>

<style scoped>
.v-input--selection-controls {
  padding-top: 0 !important;
  margin-top: 0 !important;
}
</style>
