<template>
  <DialogFormWrapper>
    <template #form>
      <v-form
        id="createCardFromTemplate"
        ref="createCardFromTemplate"
        v-model="isFormValid"
        class="custom-form pt-3"
        @submit.prevent="submitCreateCardFromTemplateForm"
      >
        <v-row>
          <v-col>
            <DateWithTimePicker
              :date-with-time="dateNow"
              label="Data i godzina"
              @changeTime="dateNow = $event"
            />
          </v-col>
        </v-row>
        <BdoCardTemplate
          :data="bdoCardTemplate"
          @update="updateValue"
        />
      </v-form>
    </template>
    <template #submit>
      <v-btn
        color="primary"
        class="base-hover"
        type="submit"
        :disabled="!isFormValid"
        form="createCardFromTemplate"
      >
        Wystaw kartę
      </v-btn>
    </template>
  </DialogFormWrapper>
</template>

<script>
import DialogFormWrapper from './Partials/DialogFormWrapper'
import DateWithTimePicker from './Inputs/DateWithTime'
import { mapActions, mapState } from 'vuex'
import BdoCardTemplate from './Inputs/BdoCardTemplate'
import { BdoCardTemplate as BdoCardTemplateModel } from '../../models'
import { DateTime } from 'luxon'
import set from 'lodash/set'

export default {
  components: {
    DialogFormWrapper,
    DateWithTimePicker,
    BdoCardTemplate
  },
  computed: {
    ...mapState({
      template: state => state.layout.dialog.item
    })
  },
  data() {
    return {
      dateNow: DateTime.now().toISO(),
      bdoCardTemplate: new BdoCardTemplateModel(),
      isFormValid: true
    }
  },
  mounted() {
    this.bdoCardTemplate = new BdoCardTemplateModel(this.template)
  },
  methods: {
    ...mapActions({
      createBdoCardFromTemplate: 'bdoCards/createBdoCardFromTemplate',
      closeDialog: 'layout/closeDialog'
    }),
    submitCreateCardFromTemplateForm() {
      this.$refs.createCardFromTemplate.validate()
      const params = {
        ...this.bdoCardTemplate,
        senderCity: this.bdoCardTemplate?.senderCity?.key,
        plannedTransportTime: this.dateNow
      }
      this.createBdoCardFromTemplate(params).then(() => {
        this.closeDialog()
      })
    },
    updateValue({ key, value }) {
      set(this.bdoCardTemplate, key, value)
    }
  }
}
</script>
